import type { GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import { getServerTranslations } from '@/tools/lib/i18n';
import { NotFoundPage } from '@/tools/views/system';

export const getStaticProps = async (context: GetStaticPropsContext) => {
  const { locale = 'en' } = context;

  const inlinedTranslation = await getServerTranslations(locale);

  return {
    props: {
      locale: locale,
      ...inlinedTranslation,
    },
  };
};

export default function Custom404(
  _props: InferGetStaticPropsType<typeof getStaticProps>
) {
  return <NotFoundPage />;
}
